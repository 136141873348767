import React from "react"
import classNames from "classnames/bind"
import Layout from "../../components/layout"
import PagePromo from "../../components/PagePromo/PagePromo"
import Badge from "../../components/Badge/Badge"
import ProposalCTA from "../../components/ProposalCTA/ProposalCTA"
import SEO from "../../components/seo"
import ToolkitTool from "../../components/ToolkitTool"
import styles from "./toolkit.module.css"

const cx = classNames.bind(styles)

const toolkit = () => {
  return (
    <Layout>
      <SEO title="Toolkit" />
      <div className={styles.introDetails}>
        <div className={styles.introDetails1} />
      </div>
      <div className={styles.container}>
        <div className={styles.hero}>
          <h1 className={styles.title}>
            Our Toolkit.
            <br /> Defined.
          </h1>
          <p className={styles.small_text}>
            We’re not into patronising anyone about the latest available tech,
            and we’re not big fans of excessive jargon, either. So we've put
            together some tools & guides to help you get started.
          </p>
        </div>

        <div className={styles.mainContent}></div>

        <div className={styles.footerContent}>
          <PagePromo
            title="Curated Tools"
            text="A collection of the best practices and tools, battle tested by us."
            link="/toolkit/curated/"
            noIntro
            image="/tools/toolkit_w_background.png"
          />
          <PagePromo
            title="Product Lexicon"
            text="Our digital jargon-buster, including key words and phrases commonly used by designers and product developers."
            link="/toolkit/product-lexicon"
            noIntro
            image="/tools/lexicon_w_background.png"
          />
          <ProposalCTA
            title="Product design and development to make your ideas a reality"
            text="Get specialised ideation, design & development support to make your ideas come to life."
            link="/contact"
            linkText="👋 Get in touch"
          />
        </div>
      </div>
    </Layout>
  )
}

export default toolkit
