import React from "react"
import { ExternalLink } from "react-feather"

import styles from "./ToolkitTool.module.css"

const ToolkitTool = props => {
  const { name, description, icon, iconImgCrop, url } = props.tool
  return (
    <a href={url} target="_blank" className={styles.toolContainer}>
      <div className={styles.toolIcon}>
        <img className={iconImgCrop && styles.toolIconImgCropped} src={icon} />
      </div>
      <div>
        <h4>{name}</h4>
        <div className={styles.external}>
          <ExternalLink />
        </div>
        <p>{description}</p>
      </div>
    </a>
  )
}

export default ToolkitTool
