import React from "react"
import styles from "./ProposalCTA.module.css"

const ProposalCTA = props => {
  const { title, text, link, linkText, smallText } = props

  return (
    <div className={styles.container}>
      <h4>{title}</h4>
      <p>{text}</p>
      <a href={link}>{linkText}</a>
      <p>{smallText}</p>
    </div>
  )
}

export default ProposalCTA
