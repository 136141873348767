import React from "react"
import classNames from "classnames/bind"
import { Link } from "gatsby"
import styles from "./PagePromo.module.css"
const cx = classNames.bind(styles)

const PagePromo = props => {
  const { title, text, link, image, cta, noIntro } = props

  const bgStyle = {
    background: "url(" + image + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }

  return (
    <div className={cx("wrapper", { wrapperShort: noIntro })}>
      {!noIntro && (
        <>
          <h3>Check this out</h3>
          <p className={styles.subtitle}>
            Want to explore more? See our other tools to learn more about the
            world of building products
          </p>
        </>
      )}
      <div className={styles.container} style={bgStyle}>
        <h4>{title}</h4>
        <p>{text}</p>
        <Link to={link}>View {cta || "now"}</Link>
      </div>
    </div>
  )
}

export default PagePromo
